import React from 'react';
import "../styles/About.css"
import { motion } from "framer-motion";
import ProfileImg from '../images/profile.jpg'

const About = () => {  
  
  const horizontal ={
    x:0, 
    opacity: 1, 
    transition:{type: 'spring', duration: 2,bounce: 0.3}
  }

  return (
      <>
          <div  className="about" id='about'>
              <div className="container">
                  <motion.div initial={{x: '-100%', opacity: 0}} whileInView={horizontal} viewport={{ once: true }} className="heading">
                    <p className="heading-sub-text">Qui suis-je</p>
                    <p className='heading-text'>Pour moi</p>
                  </motion.div>
                  <div className="split-about">
                    <motion.div initial={{x: '-100%', opacity: 0}} whileInView={horizontal} className="about-content">
                      <p>Bonjour! Je m'appelle Siméon ALYOSHEV, j'ai 36 ans et je suis en reconversion professionnelle et actuellement en deuxième année de
                        BTS SIO option SLAM en alternance. Je suis passionné par l'informatique depuis longtemps, plus particulièrement par les systèmes et réseaux au debut, et 
                        ensuite j'ai voulu apprendre et enrichir davantage mes connaissances, notamment sur la conception et le développement des applications et sites WEB,
                        et également dans le domaine de la cybersécurité.</p> 
                      <br />
                      <p>Ma curiosité et ma passion en général pour l'informatique ont été attirées par la gestion et la configuration
                        des systèmes d'exploitation et des réseaux. Tout a commencé dans les années 90 avec l'accessibilité publique des 
                        systèmes d'exploitation Windows 95/98 et internet. Ensuite j'ai continué à exercer en autodidacte plusieurs activités
                        liées à la configuration des systèmes et réseaux jusqu'en 2020 lorsque j'ai choisi de renforcer mes
                        compétences à travers mon projet personnel pour une formation en alternance BTS SIO option SLAM que j'ai donc intégrée en 2021.
                        Au cours de cette formation, j'ai eu le privilège à l'école et en entreprise de découvrir et d'apprendre différents langages de programmation 
                        et de pouvoir travailler et m'investir sur plusieurs projets en développement d'applications et de sites web.</p>  
                      <br />
                      <br />
                      <p>Durant ma formation en alternance j’ai eu l'opportunité de travailler en collaboration avec des professionnels
                         qui m’ont soutenu dans mon évolution et développement de compétences. Mon objectif actuel, en tant que professionnel, 
                         est d'appliquer l'ensemble de mes compétences pour l'élaboration et la gestion de divers projets liés aux applications 
                         et sites Web, ainsi qu'à la cybersécurité.</p>
                    </motion.div>
                    <motion.div initial={{x: '50', opacity: 0}} whileInView={horizontal}  className='about-img'>
                        <img src={ProfileImg} alt="Profile" />
                    </motion.div>
                  </div>
              </div>
          </div>
      </>
  )
};

export default About;
