import {
	FaBootstrap,
	FaCss3Alt,
	FaGithub,
	FaHtml5,
	FaNpm,
	FaReact,
	FaPython,
	FaSymfony,
	FaPhp,
} from "react-icons/fa";
import { DiJavascript1 } from "react-icons/di";
import { VscJson } from "react-icons/vsc";
import {
	SiLinux,
	SiMysql,
	SiCsharp,
	SiKalilinux,
	SiVmware,
	SiWindows,
	SiWordpress,
} from "react-icons/si";

export const SkillsData = [
	{
		name: "HTML",
		icon: <FaHtml5 />,
	},
	{
		name: "CSS",
		icon: <FaCss3Alt />,
	},
	{
		name: "Javascript",
		icon: <DiJavascript1 />,
	},
	{
		name: "PHP",
		icon: <FaPhp />,
	},
	{
		name: "JSON",
		icon: <VscJson />,
	},
	{
		name: "Bootstrap",
		icon: <FaBootstrap />,
	},
	{
		name: "React Js",
		icon: <FaReact />,
	},
	{
		name: "Symfony",
		icon: <FaSymfony />,
	},
	{
		name: "MySQL",
		icon: <SiMysql />,
	},
	{
		name: "Wordpress",
		icon: <SiWordpress />,
	},
	{
		name: "C#",
		icon: <SiCsharp />,
	},
	{
		name: "Python",
		icon: <FaPython />,
	},
	{
		name: "Git",
		icon: <FaGithub />,
	},
	{
		name: "Npm",
		icon: <FaNpm />,
	},
	{
		name: "Windows",
		icon: <SiWindows />,
	},
	{
		name: "Linux",
		icon: <SiLinux />,
	},



	{
		name: "Kali Linux",
		icon: <SiKalilinux />,
	},
	{
		name: "VMware",
		icon: <SiVmware />,
	},
];
