import React from "react";
import "../styles/projects/PPEMedSell.css";
import image0 from "../images/medsell/Image0.jpg";
import image1 from "../images/medsell/Image1.jpg";
import image2 from "../images/medsell/Image2.jpg";
import image3 from "../images/medsell/Image3.jpg";
import image4 from "../images/medsell/Image4.jpg";
import image5 from "../images/medsell/Image5.jpg";

const downloadPdf = () => {
  const link = document.createElement("a");
  link.href = process.env.PUBLIC_URL + "/docs/pappe_1_medsell.pdf";
  link.target = "_blank";
  link.download = "/docs/ppe_1_medsell.pdf";
  link.click();
};

const PPEMedSell = () => {
  return (
    <div className="medsell">
      <h2 className="medsell title">Projet Personalisé Encadré GSB MedSell</h2>
      <p className="medsell description">Projet GSB MedSell - Gestion de commandes pour la vente de produits médicaux </p>
      <p className="medsell info">Le projet Medsell est une application de gestion de commandes pour la vente de produits médicaux développée pour faciliter les transactions et communications entre le service de vente de laboratoire pharmaceutique GSB (Galaxy Swiss Bourdin) et ses clients, notament les professionnels de santé. L'objectif principal du projet était d'optimiser la gestion des commandes et d'améliorer la communication entre ses clients et l'équipe de vente du laboratoire.</p>
      <p className="medsell info">J'ai travailler sur ce projet en mode solo à partir des le premier semestre de la 2ème année de mon BTS SIO. Pour la réalisation de l'application j'ai utliser pour le coté back-end Symfony CLI avec langage PHP, le model du serveur WAMP (Windows, Apache, MySQL, PHP) avec le serveur de base de données MySQL et l'outil phpMyAdmin et pour le front-end j'ai integrer les framework des langages HTML,CSS et JavaScript, notament Twig, Bootstrap, jQuery et NodeJS.</p> <br />
      <p className="medsell info">Fonctionnalités principales et les interfaces :</p><br />
        <p className="medsell image-label">Formulaire de connexion</p>
        <div className="image-container">
        <img src={image0} alt="Image 0" />
        </div><br />
        <p className="medsell image-label">Page d'accueil</p>
        <div className="image-container">
        <img src={image1} alt="Image 1" />
        </div><br />
        <p className="medsell image-label">Interface Clients</p>
        <div className="image-container">
        <img src={image2} alt="Image 2" />
        </div><br />       
        <p className="medsell image-label">Interface Administration des utilisateurs</p>
        <div className="image-container">
        <img src={image3} alt="Image 3" />
        </div><br />       
        <p className="medsell image-label">Interface Gestion des produits et creation de commande</p>
        <div className="image-container">
        <img src={image4} alt="Image 4" />
        </div><br />
        <p className="medsell image-label">Interface Historique des commandes</p>       
        <br /> •	Gestion des commandes : L'application permet aux clients de passer des commandes de produits médicaux en spécifiant les quantités requises et les détails de livraison.
        <div className="image-container">
        <img src={image5} alt="Image 5" />
        </div><br /> 
        <ul>
          <li>
            <span>Formulaire de contact : Un formulaire de contact intuitif est disponible pour permettre aux utilisateurs de contacter facilement l'administration du laboratoire en cas de questions ou de demandes spécifiques.</span>
            <div className="image-container">
              <img src={image5} alt="Image 5" />
            </div>
          </li>
          <li>
            Le Projet GSB MedSell m'a permis de développer d'evaluer plusieurs aspects de la gestion de projet, notamment :
            <ul>
              <li>Proposer une prestation correcte, réagir aux incidents et aux demandes d'assistance et d'évolution en traitant les demandes des utilisateurs et en apportant des solutions efficaces.</li>
              <li>Travailler en mode projet en analysant les objectifs, en planifiant les activités et participer activement à la réalisation du projet.</li>
              <li>Mettre à disposition des utilisateurs un service informatique en réalisant les tests fonctionnels et d'intégration, en déployant le service et en accompagnant les utilisateurs dans sa mise en place.</li>
            </ul>
          </li>
          <li>
            Le projet Medsell a permis d'améliorer la gestion des commandes, la communication avec les clients et la visibilité en ligne du l'entreprise GSB, en offrant une interface intuitive et conviviale pour les clients et les utilisateurs.
          </li>
        </ul>
        <div className="pdf-download-button">
          <a className="pdf-download-button" href={"https://medsell.alyoshev.tech"}>
          Tésté l'application
          </a>
        </div>
      {/* Composant react-pdf pour afficher le document PDF */}
      <div className="pdf-container">
        <embed src={process.env.PUBLIC_URL + "/docs/pappe_1_medsell.pdf"} type="application/pdf" width="100%" height="1000px" />
      </div>
      <div className="pdf-download-button">
        <a href={process.env.PUBLIC_URL + "/docs/ppe_1_medsell.pdf"} download="/docs/ppe_1_medsell.pdf" onClick={downloadPdf}>
          Télécharger la documentation PPE MedSell
        </a>
        <a href={process.env.PUBLIC_URL + "/docs/pappe_1_medsell.pdf"} download="/docs/pappe_1_medsell.pdf" onClick={downloadPdf}>
          Télécharger la documentation PAPPE MedSell
        </a>
      </div>
    </div>
  );
};

export default PPEMedSell;