import React, { useState, useEffect } from "react";
import "../styles/NewsWidget.css";
import axios from 'axios';

const NewsWidget = () => {
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchNews = async (keyword) => {
    try {
      const response = await axios.get(
        `https://services.nvd.nist.gov/rest/json/cves/1.0?keyword=${encodeURIComponent(keyword)}&resultsPerPage=10`
      );
      return response.data.result.CVE_Items;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const xssNews = await fetchNews('sqli');
      const sqlInjectionNews = await fetchNews('xss');

      const mergedNews = [...xssNews, ...sqlInjectionNews]
        .map((item) => ({
          id: item.cve.CVE_data_meta.ID,
          summary: item.cve.description.description_data[0].value,
          published: item.publishedDate,
          updated: item.lastModifiedDate,
          link: `https://nvd.nist.gov/vuln/detail/${item.cve.CVE_data_meta.ID}`,
        }))
        .sort((a, b) => new Date(b.published) - new Date(a.published))
        .slice(0, 10);

      setNews(mergedNews);
      setLoading(false);
    };

    fetchData();
  }, []);

  if (loading) {
    return <p>Loading news...</p>;
  }

  return (
    <div className="news-widget">
      <h2>Les 10 derniers Web Vulnerabilités (XSS et injection SQL):</h2>
    <div className="news-widget-container">
      <ul>
        {news.map((item) => (
          <li key={item.id}>
            <a href={item.link} target="_blank" rel="noopener noreferrer">
              {item.id}
            </a>
            : {item.summary}
            <br />
            <small>Published: {new Date(item.published).toLocaleString()}</small>
          </li>
        ))}
      </ul>
    </div>
    </div>
  );
};

export default NewsWidget;