import React from "react";
import "../styles/Skills.css";
import { motion } from "framer-motion";
import { SkillsData } from "../data/SkillsData";


/*const downloadPdf = () => {
	const link = document.createElement("a");
	link.href = process.env.PUBLIC_URL + "/docs/tableau.pdf";
	link.target = "_blank";
	link.download = "/docs/tableau.pdf";
	link.click();
  };*/

const Skills = () => {
	const skillEffect = {
		y: 0,
		opacity: 1,
		transition: {
			duration: 1.4,
		},
	};

	return (
		<>
			<div className='skills' id='skills'>
				<div className='container'>
					<motion.div
						whileInView={skillEffect}
						initial={{ y: "-80px", opacity: 0 }}
						className='heading'>
						<p className='heading-sub-text'></p>
						<p className='heading-text'>Mes competences</p>
					</motion.div>
					<motion.div
						whileInView={skillEffect}
						className='skills-box'
						initial={{ y: "-80px", opacity: 0 }}>
						{SkillsData.map((el, index) => (
							<div className='skill-card' key={index}>
								<div className='skill-icon'>{el.icon}</div>
								<small className='skill-desc'>{el.name}</small>
							</div>
						))}
					</motion.div>
				</div>
			</div>
			{/*}:
			<div className="pdf-container">
        		<iframe src={process.env.PUBLIC_URL + "/docs/tableau.pdf"} width="100%" height="1000px" />
      		</div>
      		<div className="pdf-download-button">
				<a href={process.env.PUBLIC_URL + "/docs/tableau.pdf"} download>
				Télécharger le document
				</a>
			</div>*/}
		</>
	);
};

export default Skills;
