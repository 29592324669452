export const WorkData = [
	{
		title: "PPE 1 GSB MedSell",
		desc: "Projet 1 GSB Application de gestion de commandes, PPE 1 BTS SIO SLAM",
		tech: ["Symfony", "MySQL", "Twig", "Bootstrap", "HTML", "CSS", "Javascript"],
		gitlink: "//github.com/simalyos/GSB-Web-App",
		site: "/projects/ppe1medsell",
	},
	{
		title: "PPE 2 GSB NoteFee",
		desc: "Projet 2 GSB Application Note de frais  PPE 2 BTS SIO SLAM",
		tech: ["C#", "SQL Server", "Windows Forms"],
		gitlink: "//github.com/simalyos/GSB-NoteFee",
		site: "/projects/ppe2notefee",
	},
	{
		title: "GLPI",
		desc: "GLPI (Gestionnaire Libre de Parc Informatique) et OCS Inventory",
		tech: ["Windows"],
		gitlink: "",
		site: "/projects/glpi",
	},
	{
		title: "Frigolo",
		desc: "Projet de gestion des ingredients et des recettes d'un frigo virtuel connecté",
		tech: ["Symfony", "Bootstrap", "PhpMyAdmin" ],
		gitlink: "//github.com/simalyos/frigolo",
		site: "/projects/frigolo",
	},
	{
		title: "Metasploitable 2",
		desc: "Rapport de pentests sur Metasploitable 2",
		gitlink: "//github.com/simalyos/GSB-Web-App",
		tech: ["Kali Linux", "Nmap", "Metasploit" ],
		site: "/projects/metasploitable",
	},
	{
		title: "GSB Projet d'equipement",
		desc: "Proposition d'un projet d'equipement pour le laboratoire GSB",
		gitlink: "",
		tech: ["Gestion de projet" ],
		site: "/projects/gsb",
	},
	{
		title: "Activités en entreprise 1 année",
		desc: "Réalisation des macros en VBA sur Excel",
		gitlink: "",
		tech: ["Excel, MSQuery" ],
		site: "/projects/macros",
	},
	{
		title: "Activités en entreprise 1&2 année",
		desc: "Réalisations des tests de qualification sur les applications Austin et AGATHA/RCE",
		gitlink: "",
		tech: ["Excel, ToadSQL, MSQuery" ],
		site: "/projects/application1",
	},
	{
		title: "Activités en entreprise 2 année",
		desc: "Réalisations des tests sur differentes modules de l'application AGATHA/RCE",
		gitlink: "",
		tech: ["Excel, ToadSQL, MSQuery" ],
		site: "/projects/application2",
	},
];
