import React from "react";
import "../styles/TechWatch.css";
import { motion } from "framer-motion";
import NewsWidget from './NewsWidget';


const TechWatch = () => {
	const fade = {
		opacity: 1,
		transition: {
			duration: 1.4,
		},
	};

	return (
		<>
    <div className='techwatch' id='techwatch'>
				<div className='container'>
					<motion.div
						initial={{ opacity: 1 }}
						whileInView={fade}
						viewport={{ once: true }}
						className='heading'>
						<p className='heading-text'>Veille Technologique</p>
            <h3>Qu'est ce que la veille technologique ?</h3><br /> 
            <p className='heading-sub-text'>La veille technologique consiste à surveiller les évolutions techniques et les innovations dans un secteur d'activité donné. Cela comprend notamment la surveillance, la collecte, le partage et la diffusion d'information permettant d'anticiper ou de s'informer sur des changements en matière de recherche, développement, matériaux, processus, etc...</p>
            <p className='heading-sub-text'>Elle est un aspect crucial pour la sécurité web et moi comme professionnel dans ce domaine. Étant constamment exposé aux menaces potentielles et aux nouvelles vulnérabilités, j'utilise un certain nombre d'outils pour rester à jour sur les derniers développements en matière de sécurité.</p>
            <p className='heading-sub-text'>Une de mes principales ressources est l'API du système commun de vulnérabilités et d'expositions (CVE), qui me fournit des informations à jour sur les 10 dernières vulnérabilités répertoriées. Ces données me permettent de comprendre rapidement les nouvelles menaces et d'adapter mes pratiques de sécurité en conséquence.</p>
            <p className='heading-sub-text'>Je me concentre particulièrement sur les failles de sécurité liées à la sécurité web, telles que les attaques XSS (Cross-Site Scripting) et les injections SQL. Ces deux types d'attaques sont parmi les plus couramment exploités dans le cyberespace, ce qui rend la compréhension de leurs dernières variantes d'autant plus cruciale.</p>
            <p className='heading-sub-text'>Outre l'API CVE, je consulte régulièrement des blogs spécialisés, des forums de développeurs et d'autres plateformes de veille technologique pour compléter mes connaissances. Cette approche multi-facettes me permet d'obtenir une image plus complète des dernières tendances en matière de cybersécurité et de maintenir un haut niveau de sécurité dans mes projets.</p>
            <p className='heading-sub-text'>En résumé, ma veille technologique en matière de sécurité web est un processus actif et continu qui me permet de rester proactif face aux menaces émergentes. Elle est essentielle pour maintenir les systèmes que je gère sécurisés et à jour.</p>
          </motion.div>

      <div className="content-container">
        <motion.div
        className='techwatch-box'
        initial={{ opacity: 0 }}
        whileInView={fade}
      >
        {
        <div class="portfolio-section">
          <h2> La sécurité pour la protection des données sensibles sur le WEB</h2>
          <br></br>
          <p>La sécurité des données web est un enjeu majeur pour les développeurs d'applications et de sites web. La protection des informations des utilisateurs et des entreprises contre les menaces potentielles est une responsabilité cruciale. Dans ce guide approfondi, nous examinerons les meilleures pratiques, les outils et les techniques pour assurer la sécurité des données web pour les développeurs.</p>
            <ul> <br /> 
              <li><strong>Protocoles sécurisés</strong></li>
                <ul>
                    <li><strong>HTTPS</strong>: L'utilisation du protocole HTTPS garantit une connexion sécurisée entre le navigateur de l'utilisateur et le serveur. Cela protège les données transmises, notamment les informations sensibles telles que les mots de passe et les données de carte de crédit. Le protocole SSL/TLS doit être configuré correctement et les certificats SSL doivent être maintenus à jour.</li>
                </ul> <br /> 
              <li><strong>Validation et nettoyage des entrées utilisateur</strong></li>
              <ul>
                  <li>Toutes les entrées utilisateur doivent être validées et nettoyées pour éviter les attaques par injection, telles que l'injection SQL et les attaques XSS (Cross-Site Scripting). Utilisez des fonctions de nettoyage et d'échappement des données pour empêcher l'exécution de code malveillant. Mettez également en place des listes blanches et des listes noires pour contrôler les données autorisées à entrer dans l'application.</li>
              </ul> <br /> 
              <li><strong>Sécurisation des sessions</strong></li>
              <ul>
                  <li>Les sessions doivent être protégées en utilisant des identifiants de session sécurisés et en limitant la durée de vie des sessions. Utilisez des cookies sécurisés et évitez de stocker des informations sensibles dans les cookies. Mettez en place des mécanismes pour détecter et prévenir les attaques par fixation de session et les attaques CSRF (Cross-Site Request Forgery).</li>
              </ul> <br /> 
              <li><strong>Contrôle d'accès et authentification</strong></li>
              <ul>
                  <li>Implémentez un contrôle d'accès strict et une authentification solide pour protéger les données sensibles et les fonctionnalités de votre application. Utilisez l'authentification à deux facteurs (2FA) et des mots de passe sécurisés pour renforcer la sécurité. Assurez-vous que le principe du moindre privilège est appliqué, en accordant aux utilisateurs uniquement les autorisations nécessaires à leurs tâches.</li>
              </ul> <br /> 
              <li><strong>Gestion des erreurs et des logs</strong></li>
              <ul>
                  <li>Les erreurs et les logs doivent être correctement gérés pour éviter la divulgation d'informations sensibles. Évitez d'afficher des messages d'erreur détaillés aux utilisateurs et configurez un système de journalisation pour surveiller les activités suspectes. Les logs doivent être stockés dans un emplacement sécurisé et être accessibles uniquement aux personnes autorisées.</li>
              </ul> <br /> 
              <li><strong>Mises à jour régulières des dépendances et des bibliothèques</strong></li>
              <ul>
                  <li>Mettez régulièrement à jour les dépendances et les bibliothèques pour corriger les vulnérabilités connues. Utilisez des outils de détection de vulnérabilités, tels que Snyk ou Dependabot, pour vous aider à identifier les problèmes de sécurité potentiels et à les résoudre rapidement.</li>
              </ul>
            </ul> <br /> 
            <ul></ul>
              <li><strong>Les principales failles de sécurité des applications Web :</strong></li> <br /> 
              <ul>
                  <li><strong>OWASP :</strong> OWASP (Open Web Application Security Project) est un guide de sécurisation des applications web, c’est un « ouvrage » de référence des bonnes/mauvaises pratiques de développement, d’une base sérieuse en termes de statistiques, et d’un ensemble de ressources amenant à une base de réflexion sur la sécurité. La Fondation OWASP est entrée en ligne le 1er décembre 2001. Elle a été créée en tant qu'organisation caritative à but non lucratif aux Etats-Unis le 21 avril 2004 pour assurer la disponibilité et le soutien continu de notre travail à l' OWASP. Les Valeurs fondamentales d’OWASP sont : OUVERT, INNOVATION, GLOBAL, INTEGRIT.Le but d’OWASP est de permettre à la communauté mondiale prospérer en stimulent la visibilité et l'évolution de la sûreté et de la sécurité des logiciels dans le monde.</li>
              </ul> <br /> 
              <li><strong>Application pour identifier des risques de sécurité</strong></li>
              <ul>
                  <li> L'OWASP Top 10 est un document de sensibilisation puissant pour la sécurité des applications Web. Il représente un large consensus sur les risques de sécurité les plus critiques pour les applications Web. Les membres du projet incluent une variété d'experts en sécurité du monde entier qui ont partagé leur expertise pour produire cette liste.</li>
              </ul> <br /> 
              <li><strong>Risques des failles</strong></li>
              <ul>
                  <li>L’injection SQL : C’est un type d'exploitation d'une faille de sécurité d'une application interagissant avec une base de données. L'attaquant détourne les requêtes en y injectant une chaîne non prévue par le développeur et pouvant compromettre la sécurité du système.</li>
              </ul> <br /> 
              <ul>
                <li>Cross site Scripting (XSS): Il s'agit d'un type de faille de sécurité sur les sites web. Cette faille repère l'endroit où des sites web dynamique (forum, blog ...) récupère des données entrées par un utilisateur sans les avoir filtrés au préalable. 
              </li> <br /> 
              <li><strong>Mises à jour régulières des dépendances et des bibliothèques</strong></li>
              <ul>
                  <li>Mettez régulièrement à jour les dépendances et les bibliothèques pour corriger les vulnérabilités connues. Utilisez des outils de détection de vulnérabilités, tels que Snyk ou Dependabot, pour vous aider à identifier les problèmes de sécurité potentiels et à les résoudre rapidement.</li>
              </ul>
            </ul> <br /> 
            <p></p>
            <p>Pour plus d'informations sur ces vulnérabilités, veuillez consulter les références suivantes :</p>
            <ul>
              <li><a href="https://www.php.net/security">PHP Security</a></li>
              <li><a href="https://blog.jquery.com/category/security/">jQuery Security</a></li>
              <li><a href="https://dev.mysql.com/doc/relnotes/mysql/8.0/en/news-8-0-29.html">MySQL Security</a></li>
            </ul>
        </div>
        /* ... */}
        </motion.div>
      <NewsWidget />
    </div>
  </div>
</div>
		</>
	);
};

export default TechWatch;