import React from "react";
import "../styles/projects/Projects.css";


const downloadPdf = () => {
  const link = document.createElement("a");
  link.href = process.env.PUBLIC_URL + "/PPE1.pdf";
  link.target = "_blank";
  link.download = "PPE1.pdf";
  link.click();
};

const Gsb = () => {
  return (
    <div className="projects">
      <h2 className="projects title">Projet d'equipement pour l'entreprise GSB</h2>
      <p className="projects description">Proposition d'un solution d'equipementi pour le parc informatique de GSB</p>
      <p className="projects info">Le projet "Choix d'équipements pour les visiteurs" vise à trouver les meilleures solutions informatiques pour répondre aux besoins de l'entreprise GSB. Notre équipe d'experts en informatique a étudié les critères de GSB et notre objectif est de proposer des équipements et des logiciels adaptés.</p>
      <p className="projects info">Nous recommandons l'utilisation de PC portables légers avec un écran de taille standard, un système d'exploitation Windows 10, un processeur performant, un disque dur de grande capacité et une mémoire vive suffisante.</p>
      <p className="projects info">Nous recommandons l'utilisation de PC portables légers avec un écran de taille standard, un système d'exploitation Windows 10, un processeur performant, un disque dur de grande capacité et une mémoire vive suffisante.</p>
      <p className="projects info">Le projet comprend également des procédures de préparation et de récupération des équipements, ainsi qu'une charte de bon usage informatique pour garantir une utilisation appropriée des ressources informatiques.</p>
      <p className="projects info">Notre objectif est de fournir à GSB des solutions informatiques fiables et performantes pour répondre à leurs besoins en matière d'équipements pour les visiteurs.</p>
      <p className="projects info">Grâce à ces compétences, je suis en mesure de contribuer efficacement à la disponibilité, à la sécurité et à la conformité des ressources numériques au sein d'une organisation.</p>
      <div className="pdf-container">
        <iframe src={process.env.PUBLIC_URL + "/docs/gsb1-1.pdf"} width="100%" height="1000px" />
      </div>
      <div className="pdf-download-button">
        <a href={process.env.PUBLIC_URL + "/docs/gsb1-1.pdf"} download="/docs/gsb1-1.pdf" onClick={downloadPdf}>
          Télécharger le projet
        </a>
        <a href={process.env.PUBLIC_URL + "/docs/gsb1-2.pdf"} download="P/docs/gsb1-2.pdf" onClick={downloadPdf}>
          Procedures de reparations
        </a>
        <a href={process.env.PUBLIC_URL + "/docs/gsb1-3.pdf"} download="/docs/gsb1-3.pdf" onClick={downloadPdf}>
          Charte informatique
        </a>
      </div>
    </div>
  );
};

export default Gsb;