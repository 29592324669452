import React from "react";
import "../styles/projects/Basic.css";
import image0 from "../images/mtsploit/Image0.PNG";
import image1 from "../images/mtsploit/Image1.PNG";
import image2 from "../images/mtsploit/Image2.PNG";
import image3 from "../images/mtsploit/Image3.PNG";
import image4 from "../images/mtsploit/Image4.PNG";
import image5 from "../images/mtsploit/Image5.PNG";
import image6 from "../images/mtsploit/Image6.PNG";
import image7 from "../images/mtsploit/Image7.PNG";
import image8 from "../images/mtsploit/Image8.PNG";
import image9 from "../images/mtsploit/Image9.PNG";
import image10 from "../images/mtsploit/Image10.PNG";
import image11 from "../images/mtsploit/Image11.PNG";
import image12 from "../images/mtsploit/Image12.PNG";
import image13 from "../images/mtsploit/Image13.PNG";
import image14 from "../images/mtsploit/Image14.PNG";

const downloadPdf = () => {
  const link = document.createElement("a");
  link.href = process.env.PUBLIC_URL + "/docs/rapport1_cyber.pdf";
  link.target = "_blank";
  link.download = "/docs/rapport1_cyber.pdf";
  link.click();
};

const Metasploitable = () => {
  return (
    <div className="projects">
      <h2 className="projects title">TP Metasploitable 2 - Pentest Cybersécurité </h2>
      <p className="projects description">Le projet de TP Metasploitable 2 en pentest cybersécurité visait à évaluer la sécurité d'un système informatique en suivant une méthodologie complète et en utilisant des outils spécifiques. Le projet a été réalisé en plusieurs étapes, chacune jouant un rôle clé dans l'analyse des vulnérabilités et l'obtention d'un accès non autorisé au système cible.</p>
      <p className="projects info">La première étape du projet a été la phase de reconnaissance. À l'aide de l'outil NMAP, une analyse de la topologie du réseau a été effectuée pour identifier les services et les ports ouverts sur la machine cible. Cette étape a permis de recueillir des informations cruciales sur l'infrastructure, qui ont été utilisées dans les phases ultérieures du projet.</p>
      <p className="projects info">Ensuite, une analyse des vulnérabilités a été réalisée à l'aide d'outils automatisés tels que Metasploit. Des vulnérabilités critiques ont été identifiées, notamment des mots de passe faibles, des erreurs de configuration et des systèmes d'exploitation obsolètes. Ces vulnérabilités ont été exploitées pour obtenir un accès non autorisé au système cible.</p>
      <p className="projects info">La phase d'exploitation a été un aspect clé du projet. Différents exploits ont été utilisés pour exploiter les vulnérabilités identifiées. Par exemple, l'exploit FTP a été utilisé pour accéder au système en exploitant une vulnérabilité spécifique du protocole FTP. De même, d'autres exploits tels que SSH, Java, VNC et PHP CGI ont été utilisés pour obtenir un accès non autorisé à la machine cible.</p>
      <p className="medsell image-label">Diagramme de la base de données</p>
        <div className="image-container">
        <img src={image0} alt="Image 5" width="60%" height="500px" />
        </div><br />
      <p className="medsell image-label">Diagramme de la base de données</p>
        <div className="image-container">
        <img src={image1} alt="Image 5" width="60%" height="500px" />
        </div><br />
      <p className="medsell image-label">Diagramme de la base de données</p>
        <div className="image-container">
        <img src={image2} alt="Image 5" width="30%" height="500px" />
        </div><br />
      <p className="medsell image-label">Diagramme de la base de données</p>
        <div className="image-container">
        <img src={image3} alt="Image 5" width="50%" height="500px" />
        </div><br />       
      <p className="medsell image-label">Diagramme de la base de données</p>
        <div className="image-container">
        <img src={image4} alt="Image 5" width="60%" height="500px" />
        </div><br />
      <p className="medsell image-label">Diagramme de la base de données</p>
        <div className="image-container">
        <img src={image5} alt="Image 5" width="50%" height="500px" />
        </div><br />
      <p className="medsell image-label">Diagramme de la base de données</p>
        <div className="image-container">
        <img src={image6} alt="Image 5" width="60%" height="200px" />
        </div><br />
      <p className="medsell image-label">Diagramme de la base de données</p>
        <div className="image-container">
        <img src={image7} alt="Image 5" width="60%" height="500px" />
        </div><br />
      <p className="medsell image-label">Diagramme de la base de données</p>
        <div className="image-container">
        <img src={image8} alt="Image 5" width="50%" height="500px" />
        </div><br />
      <p className="medsell image-label">Diagramme de la base de données</p>
        <div className="image-container">
        <img src={image9} alt="Image 5" width="60%" height="200px" />
        </div><br />
      <p className="medsell image-label">Diagramme de la base de données</p>
        <div className="image-container">
        <img src={image9} alt="Image 5" width="60%" height="200px" />
        </div><br />
      <p className="medsell image-label">Diagramme de la base de données</p>
        <div className="image-container">
        <img src={image10} alt="Image 5" width="60%" height="200px" />
        </div><br />
      <p className="medsell image-label">Diagramme de la base de données</p>
        <div className="image-container">
        <img src={image11} alt="Image 5" width="50%" height="600px" />
        </div><br />
      <p className="medsell image-label">Diagramme de la base de données</p>
        <div className="image-container">
        <img src={image12} alt="Image 5" width="50%" height="600px" />
        </div><br />
      <p className="medsell image-label">Diagramme de la base de données</p>
        <div className="image-container">
        <img src={image13} alt="Image 5" width="50%" height="500px" />
        </div><br />
      <p className="medsell image-label">Diagramme de la base de données</p>
        <div className="image-container">
        <img src={image14} alt="Image 5" width="50%" height="600px" />
        </div><br />
      <p className="projects info">Une fois l'accès obtenu, des techniques d'élévation de privilèges ont été utilisées pour accéder à des niveaux d'autorisation supérieurs, permettant ainsi de contourner les restrictions initiales et d'obtenir un contrôle plus complet sur le système.</p>
      <p className="projects info">Tout au long du projet, des captures d'écran ont été prises pour documenter les différentes étapes et les résultats obtenus. Les captures d'écran incluent des informations telles que le scan NMAP montrant les ports ouverts, les exploits réussis et les accès obtenus à la machine cible. Ces captures d'écran fournissent une preuve concrète des actions effectuées pendant le projet.</p>
      <p className="projects info">Enfin, des recommandations de sécurité ont été formulées pour remédier aux vulnérabilités identifiées et améliorer la sécurité du système. Ces recommandations incluent des mesures telles que l'implémentation de politiques de mots de passe solides, l'application de correctifs de sécurité, l'utilisation de connexions sécurisées, des tests de pénétration réguliers et l'utilisation de pare-feux et de systèmes de détection d'intrusion.</p>
      <p className="projects info">Ce projet a permis de développer des compétences essentielles en matière d'évaluation de la sécurité, de gestion du patrimoine informatique et de travail en mode projet. Il a démontré votre capacité à utiliser des outils de pentest, à analyser les vulnérabilités, à exploiter les failles et à formuler des recommandations pour renforcer la sécurité des systèmes.</p>
      <div className="pdf-container">
        <iframe src={process.env.PUBLIC_URL + "/docs/rapport1_cyber.pdf"} width="100%" height="800px" />
        <iframe src={process.env.PUBLIC_URL + "/docs/rapport2_cyber.pdf"} width="100%" height="800px" />
      </div>
      <div className="pdf-download-button">
        <a href={process.env.PUBLIC_URL + "/docs/rapport1_cyber.pdf"} download="/docs/rapport1_cyber.pdf" onClick={downloadPdf}>
          Télécharger le rapport 1
        </a>
        <a href={process.env.PUBLIC_URL + "/docs/rapport2_cyber.pdf"} download="/docs/rapport2_cyber.pdf" onClick={downloadPdf}>
          Télécharger le rapport 2
        </a>
      </div>

    </div>
  );
};

export default Metasploitable;