import React from "react";
import "../styles/projects/Projects.css";
import image1 from "../images/first/austin.PNG";
import image2 from "../images/first/austin_test.PNG";
import image3 from "../images/first/rapport_austin.PNG";
import image4 from "../images/first/rapport_austin2.PNG";
import image5 from "../images/first/qualif_austin.PNG";
import image6 from "../images/first/qualif_austin2.PNG";
import image7 from "../images/first/msq.PNG";
import image8 from "../images/first/qualif_rce.PNG";
import image9 from "../images/first/qualif_rce2.PNG";
import image10 from "../images/first/query_aff.PNG";
import privacy from "../images/macros/privacy.jpg";
import logo from "../images/cnr.PNG";


const downloadPdf = () => {
  const link = document.createElement("a");
  link.href = process.env.PUBLIC_URL + "/PPE1.pdf";
  link.target = "_blank";
  link.download = "PPE1.pdf";
  link.click();
};

const RecetteAppli1 = () => {
  return (
    <div className="projects">
      <h3>Compagnie Nationale du Rhône</h3>
      <div className="image-container"><img src={logo} alt="Image 1" width="20%" height="120px" /></div>
      <p className="projects description">J'ai effectué mon alternance au sein de la société CNR (Compagnie Nationale du Rhône) en tant que Agent appui aux ingenieurs et attaché à la Direction de la valorisation de l'énergie. CNR est une entreprise française de production d'énergie électrique renouvelable, principalement hydraulique, et de gestion du Rhône et de ses affluents. Elle est le 1er producteur français d'électricité d'origine 100% renouvelable et le concessionnaire à vocation multiple du Rhône et de ses affluents.</p>
      <p className="projects description">Le CNR s'investit dans la conception et la mise en œuvre de différents projets visant à développer des outils informatiques internes pour la gestion de la production d'électricité, la commercialisation et la valorisation de l'énergie.</p>
      <p className="projects info">J'ai participé a des activités de recettes fonctionnelles et techniques, de support aux utilisateurs et de développement de macros VBA pour automatiser les tâches de vérification et de contrôle des données.</p>
      <p className="projects info">J'ai ainsi développé des compétences en test logiciel, en documentation et en qualité.</p>
      <h2 className="projects title">Recettes fonctionnelles et support sur l'évolution des fonctionnalités d'une application interne pour la vente d'énergie en France</h2>
      <p className="projects description">Tests sur des nouvelles modules et fonctionnalites, redaction des rapports de tests, support des utilisateurs</p>
      <p className="projects info">Réalisation et rapport des tests fonctionnels et techniques : De manière similaire à l'activité précédente, j'ai effectué des tests fonctionnels et techniques pour vérifier la conformité des nouvelles fonctionnalités de l'application. J'ai renforcé mes compétences en test logiciel, en documentation et en qualité.</p>
      <p className="projects image-label">Interface de l'application Austin</p>
        <div className="image-container">
        <img src={privacy} alt="Image 1" width="80%" height="600px" />
        </div><br />
      <p className="projects image-label">L'application Austin pendant les tests de fonctionnalites pour le trading</p>
        <div className="image-container">
        <img src={privacy} alt="Image 2" width="80%" height="600px" />
        </div><br />      
      <p className="projects image-label">Rapport de tests</p>
        <div className="image-container">
        <img src={privacy} alt="Image 3" />
        </div><br />
      <p className="projects image-label"></p>
        <div className="image-container">
        <img src={privacy} alt="Image 4" />
        </div><br />
      <p className="projects image-label">Recetes fonctionnels et qualification des bugs</p>
        <div className="image-container">
        <img src={privacy} alt="Image 5" width="80%" height="500px" />
        </div><br />
      <p className="projects image-label">Bugs non resolu</p>
        <div className="image-container">
        <img src={privacy} alt="Image 6" width="80%" height="500px"/>
        </div><br />
      <p className="projects image-label">Utilisation de SQL requetes via MSQuery pour controller et verifier la coherence des données</p>
        <div className="image-container">
        <img src={privacy} alt="Image 7" />
        </div><br />
      <p className="projects image-label">Rapport de tests sur l'application AGATHA/RCE</p>
        <div className="image-container">
        <img src={privacy} alt="Image 8" />
        </div><br />
      <p className="projects image-label"></p>
        <div className="image-container">
        <img src={privacy} alt="Image 8" />
        </div><br />
      <p className="projects image-label">Mise en place des requetes SQL via l'outil ToadSQL pour controller les calculs et la coherence des données lors de la création et la valorisation d'une affaire avec un client </p>
        <div className="image-container">
        <img src={privacy} alt="Image 8" width="80%" height="500px" />
        </div><br />
      <p className="projects info">Support des utilisateurs : En offrant un soutien aux utilisateurs de l'application, j'ai développé des compétences en résolution de problèmes, en assistance technique et en communication avec les utilisateurs finaux.</p>
      <p className="projects info">Collaboration avec les développeurs et les autres membres de l'équipe : J'ai travaillé en étroite collaboration avec les développeurs et les autres membres de l'équipe pour comprendre les aspects techniques de l'application, interagir avec différents intervenants et développer des compétences en communication et en travail d'équipe.</p>
      <p className="projects info">J'ai été impliqué dans la planification des différentes phases du ce projet, en identifiant les tâches clés, les evolution, les potentielles regression en déterminant les dépendances et en établissant des échéanciers réalistes. J'ai également contribué à l'évaluation des ressources nécessaires pour atteindre les objectifs du projet.</p>

    </div>
  );
};
export default RecetteAppli1;