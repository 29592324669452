import React, { useEffect, useState } from "react"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import '../styles/App.css';
import { motion } from "framer-motion";
import HomePage from "../pages/HomePage";
import GLPIProject from "../projects/GLPIProject";
import PPEMedSell from "../projects/PPEMedSell";
import PPENoteFee from "../projects/PPENoteFee";
import Frigolo from "../projects/Frigolo";
import Metasploitable from "../projects/Metasploitable";
import RecetteAppli2 from "../projects/RecetteAppli2";
import RecetteAppli1 from "../projects/RecetteAppli1";
import Macros from "../projects/Macros";
import Gsb from "../projects/GSB";

function App() {
  // States
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => setLoading(false), 3000)
  }, []);

  const loadText = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition:{
        duration: 1
      }
    }
  }


  return (
    loading ? 
    <div className="loader">
      <div className="svg-wrapper">
        <svg height="80" width="300" xmlns="http://www.w3.org/2000/svg">
          <rect className="shape" height="80" width="300" />
        </svg>        
        <motion.p variants={loadText} initial='hidden' animate='visible' className="text">Siméon Alyoshev Portfolio</motion.p>
      </div>
    </div>
    :
    <div className="App">
      <Router> {/* Ajout de l'enveloppe Router */}
        <Routes>
          <Route path="/projects/glpi" element={<GLPIProject />} />
          <Route path="/projects/ppe1medsell" element={<PPEMedSell />} />
          <Route path="/projects/ppe2notefee" element={<PPENoteFee />} />
          <Route path="/projects/frigolo" element={<Frigolo />} />
          <Route path="/projects/metasploitable" element={<Metasploitable />} />
          <Route path="/projects/macros" element={<Macros />} />
          <Route path="/projects/application1" element={<RecetteAppli1 />} />
          <Route path="/projects/application2" element={<RecetteAppli2 />} />
          <Route path="/projects/gsb" element={<Gsb />} />
          <Route path="/*" element={<HomePage />} />
        </Routes>
      </Router> {/* Fermeture de l'enveloppe Router */}
    </div>
  );
}

export default App;