
import React from "react";
import "../styles/projects/Projects.css";
import image1 from "../images/second/trm_2223.PNG";
import image2 from "../images/second/trm_2223-2.PNG";
import image3 from "../images/second/modul_facturation.PNG";
import image4 from "../images/second/qualif_facturation.PNG";
import image5 from "../images/second/query_rhone.PNG";
import image6 from "../images/second/query_rhone2.PNG";
import image7 from "../images/second/query_toad.PNG";
import image8 from "../images/second/rapport_facturation.PNG";
import image9 from "../images/second/rapport_facturation2.PNG";
import image10 from "../images/second/rapport_facturation3.PNG";
import image11 from "../images/second/rapport_facturation4.PNG";
import image12 from "../images/second/test_dec_rce.PNG";
import image13 from "../images/second/test_dec_rce2.PNG";
import image14 from "../images/second/test_dec_rce3.PNG";
import image15 from "../images/second/test_fu_fact.PNG";
import image16 from "../images/second/cahier_tests.PNG";
import image17 from "../images/second/ag1.PNG";
import image18 from "../images/second/ag2.PNG";
import image19 from "../images/second/ag3.PNG";
import privacy from "../images/macros/privacy.jpg";
import logo from "../images/cnr.PNG";


const downloadPdf = () => {
  const link = document.createElement("a");
  link.href = process.env.PUBLIC_URL + "/PPE1.pdf";
  link.target = "_blank";
  link.download = "PPE1.pdf";
  link.click();
};

const RecetteAppli2 = () => {
  return (
    <div className="projects">
      <h2 className="projects title">Activités en entreprise</h2>
      <h3>Compagnie Nationale du Rhône</h3>
      <div className="image-container"><img src={logo} alt="Image 1" width="20%" height="120px" /></div>
      <p className="projects description">J'ai effectué mon alternance au sein de la société CNR (Compagnie Nationale du Rhône) en tant que Agent appui aux ingenieurs et attaché à la Direction de la valorisation de l'énergie. CNR est une entreprise française de production d'énergie électrique renouvelable, principalement hydraulique, et de gestion du Rhône et de ses affluents. Elle est le 1er producteur français d'électricité d'origine 100% renouvelable et le concessionnaire à vocation multiple du Rhône et de ses affluents.</p>
      <p className="projects description">Le CNR s'investit dans la conception et la mise en œuvre de différents projets visant à développer des outils informatiques internes pour la gestion de la production d'électricité, la commercialisation et la valorisation de l'énergie.</p>
      <p className="projects info">J'ai participé des activités de recettes fonctionnelles et techniques, de support aux utilisateurs et de développement de macros VBA pour automatiser les tâches de vérification et de contrôle des données.</p>
      <p className="projects info">J'ai ainsi développé des compétences en test logiciel, en documentation et en qualité.</p>
      <h2 className="projects title">Recettes fonctionnelles et support sur l'évolution des fonctionnalités d'une application interne pour la vente d'énergie en France</h2>
      <p className="projects description">Tests sur des nouvelles modules et fonctionnalites, redaction des rapports de tests, support des utilisateurs</p>
      <p className="projects info">Réalisation et rapport des tests fonctionnels et techniques : De manière similaire à l'activité précédente, j'ai effectué des tests fonctionnels et techniques pour vérifier la conformité des nouvelles fonctionnalités de l'application. J'ai renforcé mes compétences en test logiciel, en documentation et en qualité.</p>
      <p className="projects image-label">Interface de l'application AGATHA</p>
        <div className="image-container">
        <img src={privacy} alt="Image 1" width="70%" height="600px" />
        </div><br />
      <p className="projects image-label">Affaires en cours de l'application AGATHA/RCE</p>
        <div className="image-container">
        <img src={privacy} alt="Image 1" width="80%" height="500px" />
        </div><br />
      <p className="projects image-label">Interface sites/clients de RCE</p>
        <div className="image-container">
        <img src={privacy} alt="Image 1" width="70%" height="600px" />
        </div><br />
      <p className="projects image-label">Extrait de Rapport de qualification sur le train de maintenance 2022/2023 de l'application AGATHA/RCE </p>
        <div className="image-container">
        <img src={privacy} alt="Image 1" width="80%" height="500px" />
        </div><br />
      <p className="projects image-label"></p>
        <div className="image-container">
        <img src={privacy} alt="Image 2" width="80%" height="500px"/>
        </div><br />      
      <p className="projects image-label">Extrait detaillé d'un test réalisé avec des bugs </p>
        <div className="image-container">
        <img src={privacy} alt="Image 3" width="80%" height="500px"/>
        </div><br />
      <p className="projects image-label">Requete SQL executée via ToadSQL pour recuperer la production d'electricite du Rhone par jour</p>
        <div className="image-container">
        <img src={privacy} alt="Image 5" width="40%" height="400px"/>
        </div><br />
      <p className="projects image-label">Resultat de la requete</p>
        <div className="image-container">
        <img src={privacy} alt="Image 6" width="80%" height="500px" />
        </div><br />
      <p className="projects image-label">Requete SQL pour verifier et controller les données d'un client, notament, l'energie résérvée, le contrat et d'autres informations</p>
        <div className="image-container">
        <img src={privacy} alt="Image 7" width="80%" height="500px"/>
        </div><br />
      <p className="projects image-label">Extrait de Rapport de qualification sur le module de Facturation de l'application AGATHA/RCE</p>
        <div className="image-container">
        <img src={privacy} alt="Image 4" width="80%" height="600px"/>
        </div><br />
      <p className="projects image-label">Rapport de tous les tests réaliser pour le module de Facturation</p>
        <div className="image-container">
        <img src={privacy} alt="Image 8" />
        </div><br />
      <p className="projects image-label"></p>
        <div className="image-container">
        <img src={privacy} alt="Image 0" />
        </div><br />
      <p className="projects image-label"></p>
        <div className="image-container">
        <img src={privacy} alt="Image 9" />
        </div><br />
      <p className="projects image-label"></p>
        <div className="image-container">
        <img src={privacy} alt="Image 10" />
        </div><br />
      <p className="projects image-label">Rapport de tests de regression sur le separateur décimal</p>
        <div className="image-container">
        <img src={privacy} alt="Image 11" />
        </div><br />
      <p className="projects image-label"></p>
        <div className="image-container">
        <img src={privacy} alt="Image 11" />
        </div><br />
      <p className="projects image-label"></p>
        <div className="image-container">
        <img src={privacy} alt="Image 11" />
        </div><br />
      <p className="projects image-label">Test de regression sur Facteur d'usage aberrant de module de Facturation</p>
        <div className="image-container">
        <img src={privacy} alt="Image 11" />
        </div><br />
      <p className="projects image-label">Rédaction de cahier des nouvelles test à effectuer sur des evolution de l'application AGATHA/RCE</p>
        <div className="image-container">
        <img src={privacy} alt="Image 11" width="70%" height="500px" />
        </div><br />
      <p className="projects info">Support des utilisateurs : En offrant un soutien aux utilisateurs de l'application, j'ai développé des compétences en résolution de problèmes, en assistance technique et en communication avec les utilisateurs finaux.</p>
      <p className="projects info">Collaboration avec les développeurs et les autres membres de l'équipe : J'ai travaillé en étroite collaboration avec les développeurs et les autres membres de l'équipe pour comprendre les aspects techniques de l'application, interagir avec différents intervenants et développer des compétences en communication et en travail d'équipe.</p>
      <p className="projects info">J'ai été impliqué dans la planification des différentes phases du ce projet, en identifiant les tâches clés, les evolution, les potentielles regression en déterminant les dépendances et en établissant des échéanciers réalistes. J'ai également contribué à l'évaluation des ressources nécessaires pour atteindre les objectifs du projet.</p>
    </div>
  );
};

export default RecetteAppli2;