import React from "react";
import { Link } from "react-router-dom";
import "../styles/Contact.css";
import { motion } from "framer-motion";


const Contact = () => {
    const fade = {
        opacity: 1,
        transition:{
            duration: 1.5
        }
    }

    const verticalLeft = {
        opacity: 1,
        y: 0,
        transition:{
            duration: 1.5
        }
    }


  return (
      <>
        <div className="contact" id='contact'>
            <div className="container">
                <motion.div className="heading" initial={{opacity: 0}} whileInView={fade} viewport={{ once: true }}>
                    <p className="heading-sub-text">Création de site, Pentest ou d'autres conseils informatique? </p>
                    <p className='heading-text'>Contactez moi</p>
                </motion.div>
                <div className="contact-box">
                    <motion.div className="left-box" initial={{opacity: 0, y: '-50px'}} whileInView={verticalLeft}>
                        <div className="contact-heading">
                            <p> N'hésitez pas à me contacter pour toute question ou proposition de projet. Je suis toujours ouvert aux nouvelles opportunités et je suis impatient de collaborer avec vous sur des projets passionnants. Vous pouvez me contacter en utilisant le formulaire de contact ci-dessous ou en envoyant un e-mail directement. Je m'engage à vous répondre dans les meilleurs délais.  </p>
                        </div>
                        <div className="contact-hello">
                            <p>Say Hello</p>
                            <Link className='hello-links' to="//wat/+" target='_blank'>@simalyos</Link>
                            <Link className='hello-links' to="//mailto:xxxx@gmail.com" target='_blank'>info@alyoshev.tech</Link>
                        </div>
                    </motion.div>
                    <motion.div className="right-box" initial={{opacity: 0, y: '50px'}} whileInView={verticalLeft}>
                    
                        <form
                            name="contact-form"
                            method="POST"
                            action="/send-email"
                        >
                            <input type="hidden" name="form-name" value="contact-form" />
                            <div className="form-top">
                            <div className="name">
                                <label htmlFor="name">Votre nom</label>
                                <input
                                type="text"
                                name="name"
                                id="name"
                                placeholder="Saisissez votre nom"
                                required
                                />
                            </div>

                            <div className="email">
                                <label htmlFor="email">Votre mail</label>
                                <input
                                type="email"
                                name="email"
                                id="email"
                                placeholder="Saisissez votre email"
                                required
                                />
                            </div>
                            </div>

                            <div className="form-mid">
                            <div className="message">
                                <label htmlFor="message">Votre message</label>
                                <textarea
                                type="text"
                                name="message"
                                id="message"
                                placeholder="Bonjour, je suis intéressé par vos services, pouvez-vous me contacter ?"
                                required
                                ></textarea>
                            </div>
                            </div>

                            <div className="form-btn">
                            <button type="submit" className="hero-contact">
                                Envoyer le message
                            </button>
                            </div>
                        </form>
                    </motion.div>
                </div>
            </div>
        </div>
    </>
  );
};

export default Contact;
