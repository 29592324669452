import React from 'react';
import "../styles/Services.css"
import {IoColorWandOutline} from "react-icons/io5"
import {BiCodeAlt} from "react-icons/bi"
import { motion } from "framer-motion";

const Services = () => {

   const fade = {
    opacity: 1,
    transition:{
        duration: 1.4
    }
   }

  return (
      <>
          <div className="services" id='services'>
              <div className="container">
                <motion.div whileInView={fade} viewport={{ once: true }} initial={{opacity: 0}} className="heading">
                    <p className="heading-sub-text">Que je puisse faire pour vous</p>
                    <p className='heading-text'>Mes Services</p>
                </motion.div>
                <motion.div className="services-box" whileInView={fade} initial={{opacity: 0}}>
                    <div className="services-card">
                        <BiCodeAlt className='services-icon' />
                        <p className='services-title'>Development Web</p>
                        <p className='services-desc'>Dans la création et le développement des projets j’utilise plusieurs web technologies pour rendre le contenu d'une application plus attractive, interactive, responsive et fonctionnel sur les différentes couches. Mon objectif est d'optimiser l'expérience utilisateur en utilisant des techniques d'optimisation pour des temps de chargement rapides et une navigation fluide. </p>
                    </div>
                    <div className="services-card">
                        <IoColorWandOutline className='services-icon' />
                        <p className='services-title'>OSINT & Pentesting</p>
                        <p className='services-desc'>Mes services d'OSINT et de Pentesting incluent l'identification de vulnérabilités dans les systèmes, les réseaux et les sites web, ainsi que une méthodologie correcte la recherche et la collecte d'informations sensibles en utilisant la reconnaissance. J'utilise des techniques et des outils avancés pour mener des analyses approfondies.  </p>
                    </div>
                </motion.div>
              </div>
          </div>
      </>
  )
};

export default Services;
