
import React from "react";
import "../styles/projects/Basic.css";
import image0 from "../images/frigolo/Image0.png";
import image1 from "../images/frigolo/Image1.png";
import image2 from "../images/frigolo/Image2.png";
import image3 from "../images/frigolo/Image3.png";

const downloadPdf = () => {
  const link = document.createElement("a");
  link.href = process.env.PUBLIC_URL + "/PPE1.pdf";
  link.target = "_blank";
  link.download = "PPE1.pdf";
  link.click();
};

const Frigolo = () => {
  return (
    <div className="basic">
      <h2 className="basic title">Frigolo</h2>
      <p className="basic description">Premier petit projet à l'ecole realiser via le framework Symfony, Twig et Bootstrap. </p>
      <p className="basic info">L'objectif de ce projet éte de créer un frigo virtuele avec des ingredients et des recettes et ensuite de créer et parametrer les interactions entre les deux interface en utilisant Symfony, Twig, Bootstrap i Javascript</p>
      <div className="image-container">
        <img src={image0} alt="Image 0" />
      </div><br /> 
      <p className="basic info">Réalisation et rapport des tests fonctionnels et techniques : Cette responsabilité m'a amené à concevoir et à exécuter des tests fonctionnels et techniques pour vérifier la conformité de l'application aux spécifications fonctionnelles. J'ai ainsi développé des compétences en conception de cas de test, en exécution de tests et en reporting des résultats.</p>
      <div className="image-container">
        <img src={image1} alt="Image 1" />
      </div><br /> 
      <p className="basic info">Réalisation et rapport des tests fonctionnels et techniques : Cette responsabilité m'a amené à concevoir et à exécuter des tests fonctionnels et techniques pour vérifier la conformité de l'application aux spécifications fonctionnelles. J'ai ainsi développé des compétences en conception de cas de test, en exécution de tests et en reporting des résultats.</p>
      <div className="image-container">
        <img src={image2} alt="Image 2" />
      </div><br /> 
      <p className="basic info">Réalisation et rapport des tests fonctionnels et techniques : Cette responsabilité m'a amené à concevoir et à exécuter des tests fonctionnels et techniques pour vérifier la conformité de l'application aux spécifications fonctionnelles. J'ai ainsi développé des compétences en conception de cas de test, en exécution de tests et en reporting des résultats.</p>
      <div className="image-container">
        <img src={image3} alt="Image 3" />
      </div><br /> 
      
          <p className="basic info" >Ce petit projet Symfony m'a permis un premier developpement en mode projet, notamment : </p>
            <ul>
              <li>Travailler en mode projet en analysant les objectifs, en planifiant les activités et participer activement à la réalisation du projet.</li>
              <li>Mettre à disposition des utilisateurs un service informatique en réalisant les tests fonctionnels et d'intégration, en déployant le service et en accompagnant les utilisateurs dans sa mise en place.</li>
            </ul>
           <p className="basic info" >Le projet Medsell a permis d'améliorer la gestion des commandes, la communication avec les clients et la visibilité en ligne du l'entreprise GSB, en offrant une interface intuitive et conviviale pour les clients et les utilisateurs.</p> 
    
      <div className="pdf-download-button">
        <a href="https://github.com/simalyos/frigolo">
          Acceder au projet sur GitHub
        </a>
      </div>
    </div>
  );
};

export default Frigolo;