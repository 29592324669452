
import React from "react";
import "../styles/projects/Projects.css";
import image1 from "../images/macros/macro_conso1.PNG";
import image2 from "../images/macros/macro_conso2.PNG";
import image3 from "../images/macros/macro_conso3.PNG";
import image4 from "../images/macros/macro_conso4.PNG";
import image5 from "../images/macros/code_conso1.PNG";
import image6 from "../images/macros/code_conso2.PNG";
import image7 from "../images/macros/code_conso3.PNG";
import image8 from "../images/macros/code_conso4.PNG";
import image9 from "../images/macros/macro_prev1.PNG";
import image10 from "../images/macros/macro_prev2.PNG";
import image11 from "../images/macros/code_prev1.PNG";
import image12 from "../images/macros/code_prev2.PNG";
import privacy from "../images/macros/privacy.jpg";
import logo from "../images/cnr.PNG";


const downloadPdf = () => {
  const link = document.createElement("a");
  link.href = process.env.PUBLIC_URL + "/PPE1.pdf";
  link.target = "_blank";
  link.download = "PPE1.pdf";
  link.click();
};

const Macros = () => {
  return (
    <div className="projects">
      <h2 className="projects title">Activités en entreprise</h2>
      <h3>Compagnie Nationale du Rhône</h3>
      <div className="image-container"><img src={logo} alt="Image 1" width="20%" height="120px" /></div>
      <p className="projects description">J'ai effectué mon alternance au sein de la société CNR (Compagnie Nationale du Rhône) en tant que Agent appui aux ingenieurs et attaché à la Direction de la valorisation de l'énergie. CNR est une entreprise française de production d'énergie électrique renouvelable, principalement hydraulique, et de gestion du Rhône et de ses affluents. Elle est le 1er producteur français d'électricité d'origine 100% renouvelable et le concessionnaire à vocation multiple du Rhône et de ses affluents.</p>
      <p className="projects description">Le CNR s'investit dans la conception et la mise en œuvre de différents projets visant à développer des outils informatiques internes pour la gestion de la production d'électricité, la commercialisation et la valorisation de l'énergie.</p>
      <p className="projects info">J'ai participé a des activités de recettes fonctionnelles et techniques, de support aux utilisateurs et de développement de macros VBA pour automatiser les tâches de vérification et de contrôle des données.</p>
      <p className="projects info">J'ai ainsi développé des compétences en test logiciel, en documentation et en qualité.</p>

      <h2 className="projects title">Réalisation de 2 macros sous VBA sur Excel</h2>
      <p className="projects description">Pour repondre au besoins des utilisateurs au sein de l'entreprise pour automatiser leurs taches lors de verification et control des données, les premieres missions au debut de mon alternance a été pour réaliser 2 macros pour repondres aux utilisateurs</p>
      <p className="projects info">Vérification de l'historique de consommation d'un client : Cette tâche a nécessité une analyse approfondie des données d'historique de consommation, la maîtrise de VBA pour automatiser les vérifications et la manipulation des dates et heures. Cela a développé mes compétences en programmation VBA, en gestion des données et en résolution de problèmes.</p>
      <p className="projects info">Vérification de la prévision de consommation : Pour cette activité, j'ai dû créer une macro qui permettait de comparer les prévisions de consommation sur une période donnée avec les données réelles. Cela a exigé une compréhension précise des prévisions, la création de requêtes et de calculs appropriés en utilisant VBA. J'ai ainsi développé des compétences en analyse comparative, en traitement de données et en automatisation.</p>
      <p className="projects image-label">Macro pour verification l'historique de consommation d'un client pour une periode choisie</p>
        <div className="image-container">
        <img src={image1} alt="Image 1" width="70%" height="300px" />
        </div><br />
        <p className="projects image-label">MessageBox si aucune données ont été trouvées</p>
        <div className="image-container">
        <img src={image2} alt="Image 2" width="70%" height="300px" />
        </div><br />      
      <p className="projects image-label">Relancement de la verification pour une periode differentes avec MessageBox comme notification que l'année est incomplete et sur les données disponible il y a des jours ou des heures sans données</p>
        <div className="image-container">
        <img src={image3} alt="Image 3" width="70%" height="300px" />
        </div><br />
      <p className="projects image-label">Ensuite avec le bouton "Controle des heures manquantes" on trouve les ligne entre lequelles il n'existent pas des données</p>
        <div className="image-container">
        <img src={image4} alt="Image 4" width="70%" height="500px"/>
        </div><br />
      <p className="projects image-label">Voici le code VBA de la macro avec une connexion à la base de données et la requete executée en vue de recuperer les données et les inserer dans le tableau sur le fichier Excel :</p>
        <div className="image-container">
        <img src={privacy} alt="Image 5" width="70%" height="500px"/>
        </div><br />
      <p className="projects image-label"></p>
        <div className="image-container">
        <img src={privacy} alt="Image 6" width="70%" height="500px"/>
        </div><br />
      <p className="projects image-label">Code de la deuxième macro pour detecter les lignes pour les dates ou les heures manquantes inserer avec la premiere macro dans le fichier </p>
        <div className="image-container">
        <img src={privacy} alt="Image 7" width="70%" height="500px"/>
        </div><br />
      <p className="projects image-label"></p>
        <div className="image-container">
        <img src={privacy} alt="Image 8" width="70%" height="500px"/>
        </div><br />
      <p className="projects image-label">Macro pour afficher la prevision de consommation d'un client avec son code et la periode selectionnée</p>
        <div className="image-container">
        <img src={privacy} alt="Image 0" width="70%" height="300px"/>
        </div><br />
      <p className="projects image-label">Si aucune donnée est trouvée un message d'alerte est affiché. Sinon les données sont recuperer depuis la base de données et inserer dans le tableau</p>
        <div className="image-container">
        <img src={privacy} alt="Image 9" width="70%" height="500px"/>
        </div><br />
      <p className="projects image-label">Voici le code VBA de cette macro pour se connecter à la base de données, executer la requete definie et recuperer les données et leurs mise en forme sur le fichier Excel :</p>
        <div className="image-container">
        <img src={privacy} alt="Image 10" width="70%" height="500px"/>
        </div><br />
      <p className="projects image-label"></p>
        <div className="image-container">
        <img src={privacy} alt="Image 11" width="70%" height="500px"/>
        </div><br />
      <p className="projects info">J'ai eu l'occasion de participer au projet GSB MedSell, qui m'a permis de développer et d'évaluer plusieurs aspects de la gestion de projet. Voici quelques points importants que j'ai pu aborder :</p>
      <ul>
              <li>J'ai été impliqué dans la proposition de solutions adaptées, la gestion des incidents et des demandes d'assistance et d'évolution. J'ai travaillé activement pour répondre aux demandes des utilisateurs et trouver des solutions efficaces.</li>
              <li>J'ai également eu l'occasion de travailler en mode projet, en analysant les objectifs, en planifiant les activités et en contribuant activement à la réalisation du projet.</li>
              <li>Une partie de mes responsabilités consistait à mettre en place un service informatique de qualité pour les utilisateurs. Cela impliquait la réalisation de tests fonctionnels et d'intégration et l'accompagnement des utilisateurs lors de sa mise en place.</li>
      </ul>
    </div>
  );
};

export default Macros;