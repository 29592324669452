import React from "react";
import "../styles//projects/GLPIProject.css";
import image0 from "../images/glpi/Image0.png";
import image1 from "../images/glpi/Image1.png";
import image2 from "../images/glpi/Image2.png";
import image3 from "../images/glpi/Image3.png";
import image4 from "../images/glpi/Image4.png";
import image5 from "../images/glpi/Image5.png";

const downloadPdf = () => {
  const link = document.createElement("a");
  link.href = process.env.PUBLIC_URL + "/docs/glpi.pdf";
  link.target = "_blank";
  link.download = "/docs/glpi.pdf";
  link.click();
};

const GLPIProject = () => {
  return (
    <div className="glpi" >
      <h2 className="glpi title">Projet GLPI avec plugin OCS Inventory</h2>
      <p className="glpi description">L'objectif de ce projet GLPI avait pour objectif de mettre en place un système de gestion, de suivi et d'administration du patrimoine informatique d'une entreprise, en recensant et en identifiant de manière précise les ressources numériques, tout en exploitant des référentiels, normes et standards du secteur.</p>
      <p className="glpi info">Pour atteindre cet objectif, j'ai évalué deux outils, OCS Inventory et Fusion Inventory, associés à GLPI, afin de déterminer la solution la mieux adaptée aux besoins de l'entreprise. Après une analyse approfondie, j'ai choisi d'utiliser Fusion Inventory en raison de sa parfaite intégration avec GLPI et de sa simplicité d'installation.</p>
      <p className="medsell image-label">Formulaire de connexion</p>
        <div className="image-container">
        <img src={image0} alt="Image 0" />
        </div><br />
      <p className="glpi info">Dans le cadre du projet, j'ai procédé à l'installation de GLPI sur un serveur, en utilisant un environnement LAMP/WAMP (serveur web Apache avec support PHP et base de données MySQL). J'ai configuré GLPI pour recenser et gérer les équipements informatiques, les licences logicielles, les contrats de maintenance et les interventions techniques.</p>
      <p className="medsell image-label">Formulaire de connexion</p>
        <div className="image-container">
        <img src={image1} alt="Image 1" />
        </div><br />
      <p className="glpi info">J'ai utilisé l'interface principale de GLPI pour accéder aux différentes fonctionnalités du système. Cette interface centralisée offre une vue d'ensemble du patrimoine informatique et permet de gérer les équipements, les licences, les contrats et les interventions.</p>
      <p className="medsell image-label">Administration des utilisateurs</p>
        <div className="image-container">
        <img src={image2} alt="Image 2" />
        </div><br />
      <p className="glpi info">Pour une gestion amelliorer et automatic des équipements, j'ai importer et installer le plugin OCS Inventory dans GLPI pour pouvoir enregistré les informations détaillées sur chaque composant matériel et logiciel, telles que la localisation, l'affectation aux utilisateurs et les caractéristiques techniques. J'ai également configuré les niveaux d'habilitation pour contrôler l'accès aux informations sensibles.</p>
      <p className="medsell image-label">OCS Inventory</p>
        <div className="image-container">
        <img src={image3} alt="Image 3" width="60%" height="500px" />
        </div><br />
      <p className="glpi info">Une gestion des licences logicielles et des contrats et disponible egalement en enregistrant les informations sur les licences, en suivant leur utilisation et en veillant à leur conformité aux termes et conditions établis.</p>
      <p className="medsell image-label">Formulaire de connexion</p>
        <div className="image-container">
        <img src={image4} alt="Image 4" width="60%" height="500px" />
        </div><br />
      <p className="glpi info"></p>
      <p className="medsell image-label">Comparaison de OCS Inventory et Fusion Inventory</p>
        <div className="image-container">
        <img src={image5} alt="Image 5" width="50%" height="500px" />
        </div><br />
      <p className="glpi info">Enfin, j'ai utilisé GLPI pour simuler une activiter d'enregistrement des informations relatives aux sauvegardes, automatiser le suivi du parc informatique, telles que les équipements utilisés, les dates et les emplacements de stockage. Bien que la fonctionnalité de sauvegarde ne soit pas native à GLPI, j'ai pu centraliser les données liées aux sauvegardes pour faciliter leur gestion.</p>
      <p className="glpi info">Ce projet GLPI m'a permis de decouvrir, de progresser et développer des compétences organisationnels et techniques en matière de gestion du parc informatique, notamment en ce qui concerne le recensement et l'identification des ressources numériques, l'exploitation de référentiels et de normes, la gestion des niveaux d'habilitation, la planification des opérations de maintenance, la gestion des licences et des sauvegardes.</p>
      <p className="glpi info">Grâce à ces connaissances, je suis en mesure de m'investir efficacement à la disponibilité, à la sécurité et à la conformité des ressources numériques au sein d'une organisation.</p>
      <div className="pdf-download-button">
        <a href={process.env.PUBLIC_URL + "/docs/glpi.pdf"} download="/docs/glpi.pdf" onClick={downloadPdf}>
          Télécharger la documentation du projet
        </a>
      </div>
      <div className="pdf-container">
        <embed src={process.env.PUBLIC_URL + "/docs/glpi.pdf"} type="application/pdf" width="100%" height="800px" />
      </div>

    </div>
  );
};

export default GLPIProject;