import React from "react";
import "../styles/projects/PPEMedSell.css";
import image0 from "../images/notefee/Image0.png";
import image01 from "../images/notefee/Image0.1.png";
import image02 from "../images/notefee/Image0.2.png";
import image1 from "../images/notefee/Image1.png";
import image2 from "../images/notefee/Image2.png";
import image3 from "../images/notefee/Image3.png";
import image4 from "../images/notefee/Image4.png";
import image5 from "../images/notefee/Image5.png";
import image6 from "../images/notefee/Image6.png";
import image7 from "../images/notefee/Image7.png";
import image8 from "../images/notefee/Image8.png";
import image9 from "../images/notefee/Image9.png";
import image10 from "../images/notefee/Image10.png";

const downloadPdf = () => {
  const link = document.createElement("a");
  link.href = process.env.PUBLIC_URL + "/docs/pappe_2_notefee.pdf";
  link.target = "_blank";
  link.download = "/docs/pappe_2_notefee.pdf";
  link.click();
};

const PPENoteFee = () => {
  return (
    <div className="medsell">
      <h1>Page du projet PPE 2 NoteFee</h1>
      <h2 className="medsell title">GSB NoteFee Projet</h2>
      <p className="medsell description">Le projet NoteFee est une application pour la gestion des frais lors d'un déplacement, de repas et d'hébergement et autres, générés par l'activité de chaque employé de laboratoire GSB. Son objectif est de permettre une gestion précise, uniforme et suivi des frais entre les entités de l'entreprise GSB.</p>
      <p className="medsell info">L'application, développée en utilisant le langage de programmation C# et une base de données SQL Server, offre une interface conviviale permettant aux visiteurs de saisir leurs dépenses, de visualiser le suivi des remboursements et de gérer les justificatifs fournis.</p>
      <p className="medsell image-label">Diagramme de la base de données</p>
        <div className="image-container">
        <img src={image1} alt="Image 5" />
        </div><br />
      <p className="medsell image-label">Diagramme de cas d'utilisation</p>
        <div className="image-container">
        <img src={image2} alt="Image 5" />
        </div><br />
      <p className="medsell image-label">Formulaire de connexion</p>
        <div className="image-container">
        <img src={image5} alt="Image 5" />
        </div><br />
        <p className="medsell image-label">Extrait du code de la fonction de connexion et l'access au l'onglet d'accueil</p>
        <div className="image-container">
        <img src={image0} alt="Image 0" />
        </div><br />
        <div className="image-container">
        <img src={image01} alt="Image 0" />
        </div><br />
        <div className="image-container">
        <img src={image02} alt="Image 0" />
        </div><br />
        
      <p className="medsell info">Les principales fonctionnalités du projet NoteFee incluent :</p>
      <p className="medsell info">Enregistrement des frais : Les utilisateurs peuvent saisir les détails de leurs frais de déplacement, de restauration et d'hébergement, en spécifiant le type de frais, en ajoutant un commentaire et en téléchargeant les justificatifs.</p>
        <div className="image-container">
        <img src={image7} alt="Image 7" />
        </div><br />
      <p className="medsell info">Suivi des notes saisies : L'application permet aux utilisateurs de visualiser l'historique de leurs depenses deja enregistrer, incluent toutes les informations pour la note de frais.</p>
        <div className="image-container">
        <img src={image8} alt="Image 8" />
        </div><br />
      <p className="medsell info">Gestion des justificatifs : Les utilisateurs peuvent télécharger et associer des justificatifs à leurs demandes de remboursement, facilitant ainsi la vérification et la validation ultérieure.</p>
        <div className="image-container">
        <img src={image9} alt="Image 9" />
        </div><br />
      <p className="medsell info">Sécurité et authentification : Le projet intègre des mécanismes de sécurité pour protéger les données sensibles des utilisateurs et assure une authentification sécurisée pour garantir l'accès aux seuls utilisateurs autorisés.</p>
      <p className="medsell info">Ce projet a permis le développement de compétences clés, notamment :</p>
      <p className="medsell info">Programmation en C# pour la mise en place des fonctionnalités de l'application.
          Conception d'interfaces utilisateur intuitives et esthétiques pour une expérience utilisateur agréable.
          Tests et validation de l'application pour garantir son bon fonctionnement et sa qualité.
          Mise en place de mesures de sécurité pour protéger les données des utilisateurs.
          Travail en mode projet, en respectant les objectifs, les délais et les étapes de développement.</p>
          <ul>
            <li>
            Le Projet GSB NoteFee m'a permis de développer d'evaluer plusieurs aspects de la gestion de projet, notamment :
            <ul>
              <li>Proposer une prestation correcte, réagir aux incidents et aux demandes d'assistance et d'évolution en traitant les demandes des utilisateurs et en apportant des solutions efficaces.</li>
              <li>Travailler en mode projet en analysant les objectifs, en planifiant les activités et participer activement à la réalisation du projet.</li>
              <li>Mettre à disposition des utilisateurs un service informatique en réalisant les tests fonctionnels et d'intégration, en déployant le service et en accompagnant les utilisateurs dans sa mise en place.</li>
            </ul>
          </li>
          <li>
            Le projet NoteFee a permis d'améliorer la gestion des commandes, la communication avec les clients et la visibilité en ligne du l'entreprise GSB, en offrant une interface intuitive et conviviale pour les clients et les utilisateurs.
          </li>
        </ul>
      <div className="pdf-container">
        <embed src={process.env.PUBLIC_URL + "/docs/pappe_2_notefee.pdf"} type="application/pdf" width="100%" height="1000px" />
      </div>
      <div className="pdf-download-button">
        <a href={process.env.PUBLIC_URL + "/docs/ppe_2_notefee.pdf"} download="ppe_2_notefee.pdf" onClick={downloadPdf}>
          Télécharger le PPE GSB NoteFee
        </a>
        <a href={process.env.PUBLIC_URL + "/docs/pappe_2_notefee.pdf"} download="pappe_2_notefee.pdf" onClick={downloadPdf}>
          Télécharger le PAPPE GSB NoteFee
        </a>
      </div>
    </div>
  );
};

export default PPENoteFee;